<template>
      
  <div class="Introduction_all">
    <div class="Introduction_swiper" v-if="list.tj_images">
      <img :src="list.tj_images" alt="" />
    </div>

     <div class="Introduction_white"></div>
    <div class="Introduction_Deatils">
      <div class="Introduction_title">
        <!-- <img src="../assets/img/" alt=""> -->
        <img src="../assets/left.png" alt="" /> 展览介绍
        <img src="../assets/right.png" alt="" />
      </div>
      <div v-html="list.details"></div>
      <!-- <div class='Introduction_text'>

        <p class="Introduction_goods_one">
          　　春节假期，新、老馆展出多项精彩纷呈的展览，除了&ldquo;顺义文明史陈列（新馆）&rdquo;&ldquo;徽州古建筑（新馆）&rdquo;&ldquo;顺义文房四宝（新馆）&rdquo;&ldquo;烽火江淮&mdash;&mdash;顺义革命史陈列（老馆）&rdquo;等常设展览，还为大家特别推荐&ldquo;&lsquo;牛&rsquo;转乾坤&mdash;&mdash;辛丑（牛年）新春生肖文物图片联展&rdquo;&ldquo;新安江上桃花红&mdash;&mdash;明清时期徽州女性文物展&rdquo;&ldquo;家在黄山白岳之间&mdash;&mdash;渐江书画艺术展&rdquo;&ldquo;决胜&mdash;&mdash;全面小康路上的顺义&rdquo;等新展，欢迎大家前来了解传统年俗文化，聆听徽州女性故事，感受书画魅力，探寻小康故事。
        </p>

        <p class="Introduction_goods_one" style='text-align:center;'>
          <img
            src="https://file.ahm.cn:444/AnHuiMuseum/%E5%AF%8C%E6%96%87%E6%9C%AC%E7%BC%96%E8%BE%91%E5%99%A8%E6%96%87%E4%BB%B6/EditorImage/20210205/6374813311557615353885208.jpg"
          />
        </p>

        <p class="Introduction_goods_one">
          　　2021告别庚子鼠岁，迎来辛丑牛年。&ldquo;&lsquo;牛&rsquo;转乾坤&mdash;&mdash;辛丑（牛年）新春生肖文物图片联展&rdquo;是在国家文物局支持下，由顺义博物院与中国文物报社等40余家文博机构联合举办，我院精心遴选37张牛文物图版进行展示。这里有不同材质、不同工艺所塑造的牛形象、牛装饰文物，千姿百态，各具其妙，共同营造出传统新春佳节迎春纳福的喜庆氛围，也期待在新的一年里牛雄壮、敦厚、朴实的优秀品质，给人们带来力量、鼓舞和希望。
        </p> -->

        <!-- <p class="goods_one">
          　　展览时间：2021/2/5-
        </p>

        <p class="show_five">
          　　展览地点：顺义博物院新馆五楼临展厅
        </p> -->

        <!-- <p class="Introduction_goods_one" style='text-align:center;'>
          <img
            src="https://file.ahm.cn:444/AnHuiMuseum/%E5%AF%8C%E6%96%87%E6%9C%AC%E7%BC%96%E8%BE%91%E5%99%A8%E6%96%87%E4%BB%B6/EditorImage/20210205/6374813329135726228887826.jpg"
          />　　
        </p>

        <p class="Introduction_goods_one">
          &ldquo;新安江上桃花红&mdash;&mdash;明清时期徽州女性文物展&rdquo;分为&ldquo;灼灼其华：闺阁▪才媛&rdquo;&ldquo;桃李争妍：女红▪妆饰&rdquo;&ldquo;宜室宜家：婚姻▪家庭&rdquo;&ldquo;下自成蹊：社会▪典范&rdquo;四个部分，遴选展品160余件套，旨在依托文化实物遗存，再现历史上徽州女性的生动形象。展览不仅以女性的视角去讲述女性故事，更在引导观众关注女性生活，关注新安江文化，以传播优秀地域文化和弘扬传承中华美德为最终目的。
        </p>
      </div> -->
      <div class="Introduction_xuxian"></div>
      <Share :titles="list.name" :images="list.images"></Share>
    </div>
    <Gfoot v-if="flag"></Gfoot>
  </div>
</template>

<script>
import Gfoot from "../components/footer";
import { fetch } from "../api/https";
import Share from '../components/share'
export default {
  data() {
    return {
      imgSwiper: [
        {
          titile: "充值",
          img:
            "https://p.ssl.img.360kuai.com/dmfd/158_88_75/t014d23ad868d072607.webp?size=423x272",
        },
        {
          titile: "站上",
          img:
            "https://p.ssl.img.360kuai.com/dmfd/158_88_75/t015a2dc3bf11b675eb.webp?size=626x396",
        },
        {
          titile: "点击",
          img:
            "https://p.ssl.img.360kuai.com/dmfd/158_88_75/t01b311f92da49eb8df.webp?size=627x410",
        },
        {
          titile: "小牧",
          img:
            "https://p0.ssl.img.360kuai.com/dmfd/158_88_75/t01237e64a391b12451.webp?size=500x269",
        },
      ],
      id: "",
      list: "",
      flag: false,
    };
  },
  components: {
    Gfoot,
    Share
  },
  created() {},
  mounted() {
    this.axio();
  },
  methods: {
    axio() {
      fetch(`/activity_details?id=${this.$route.query.id}`)
        .then((res) => {
          this.list = res.data;
          document.getElementsByClassName;
          this.flag = true;
          document.title = this.list.name
          this.list.forEach((item,key)=>{
            item.create_time = moment(1000*parseInt(item.create_time)).format(
              "YYYY-MM-DD"
            )
          })
        })
        .catch((err) => {});
    },

  },
};
</script>

<style scoped lang="scss">
.Introduction_all {
  background: rgb(249, 249, 249);
  height: auto;
  width: 100%;
}
.Introduction_white{
  background: rgb(249, 249, 249);
  height: 62px;
}
.Introduction_swiper {
  max-height: 670px;
  width: 100%;
}
.Introduction_swiper img {
  max-height: 670px;
  width: 100%;
}
.Introduction_title {
  text-align: center;
  max-width: 1400px;
  margin: 0 auto;
  font-size: 29px;
  color: #b26c30;
  padding-bottom: 49px;
  position: relative;
  img {
    width: 57px;
    height: 26px;
  }
}
.Introduction_Deatils {
  max-width: 1400px;
  padding: 60px 101px;
  padding-bottom: 40px;
  box-sizing: border-box;
  margin: 0 auto;
  margin-bottom: 60px;
  // margin-top: ;
  background-color: white;
  .Introduction_DetailHeader {
    font-size: 14px;
    color: #666666;
    .Introduction_leftTitle {
      margin-right: 20px;
      float: left;
      img {
        margin-top: -1px;
        float: left;
        margin-right: 10px;
      }
    }
    color: #333333;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    line-height: 32px;
    .Introduction_rightTitle {
      float: left;
      img {
        float: left;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
    padding-bottom: 32px;
  }
  .Introduction_imgText {
    p {
      line-height: 39px;
      font-size: 14px;
      text-indent: 2em;
    }
  }
  .Introduction_banners {
    margin-bottom: 70px;
    max-width: 1400px;
    height: 620px;
    //   background: lightgreen;
    position: relative;
    .Introduction_leftImg {
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -30px;
    }
    .Introduction_rightImg {
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -30px;
    }
    .Introduction_RIghtshop {
      position: absolute;
      left: 10%;
      top: 50%;
      margin-top: -192px;
      img {
        height: 385px;
        width: 260px;
        box-shadow: 5px 5px 5px;
      }
      line-height: 40px;
      font-size: 15px;
      text-align: center;
    }
    .Introduction_centershop {
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -130px;
      margin-top: -230px;
      img {
        height: 459px;
        width: 310px;
        box-shadow: 5px 5px 5px;
      }
      line-height: 40px;
      font-size: 15px;
      text-align: center;
    }
    .Introduction_leftshop {
      position: absolute;
      right: 10%;
      top: 50%;
      margin-top: -192px;
      img {
        height: 385px;
        width: 260px;
        box-shadow: 5px 5px 5px;
      }
      line-height: 40px;
      font-size: 15px;
      text-align: center;
    }
  }
  .Introduction_goods_one{
    font-size: 14px;
    text-indent: 2em;
font-family: Microsoft YaHei;
font-weight: 400;
color: #333333;
line-height: 32px;
  }
  .Introduction_goods_one img{
    margin-top:34px ;
    margin-bottom: 72px;
  }
  .Introduction_xuxian{
    height: 1px;
    width: 1200px;
    margin: 0 auto;
    margin-top: 50px;
    background: url('../assets/museumDetails/dotteLine.png');
  }
}
</style>
<style >
p {
  line-height: 30px;
}
>>> span {
  font-family: MicrosoftYaHei;
}
.show_title{
  text-align:center;
}
@media screen and (max-width: 1440px){
  .Introduction_Deatils{
    width: 1266px;
  }
}
.Introduction_goods_one span{
  color:#666666 !important;
}
</style>
